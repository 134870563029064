import Sdata from '@/database/sdata'

let g_entity = 'studio_list_compte'
let g_url    = 'studio/listcompte'
let g_pkey   = ['uid']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class ListCompte extends Sdata
{
  static entity = g_entity
  static url    = g_url
}