//import SimpleCrypto from "simple-crypto-js";
//import uuid from '@/assets/utils/uuid'
import Vue from 'vue'
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate';

import autoRefresh from '@/assets/utils/autoRefresh'


//const member_crypto = new SimpleCrypto( uuid.get() );


export default class Sdata
{
  static entity = '?'//Nom de l'antité
  static url    = 'studio/?';//Url serveur REST pour accés ressources serveur
  static pkey   = ['uid'];//primary key and secondary key

  static memory = {}; // memoire d'une table
  static memory_load = {}; // bool de si la table est chargé
  static memory_time = new Date(); // Date dernier refresh
  static memory_callback = {};// callback memory qui met en attente les request quand la table ce charge

  static memory_config = {};//Contient la config pour les refresh


  //-------------------------------------------------------------
  // Config add for refresh
  //-------------------------------------------------------------
  static addConfig( entity, key, url )
  {
    entity = entity.toLowerCase()
    this.memory_config[ entity ] = {}

    this.memory_config[ entity ].entity = entity
    this.memory_config[ entity ].key    = key
    this.memory_config[ entity ].url    = url
  }


  //-------------------------------------------------------------
  // get all memory
  //-------------------------------------------------------------
  static getAllMemory( callback )
  {
    let root = this;

    if(root.memory_load[root.entity])
      return callback( root.getKeyMemory() );
    else
      root.load(function(){
        return callback( root.getKeyMemory() );
      });
  }

  static AwaitGetAllMemory()
  {
    return new Promise((resolve)=>
    {
      this.getAllMemory(resolve);
    })
  }


  

  static getAllMemoryKey( key, callback )
  {
    let root = this;

    if(root.memory_load[root.entity])
      return callback( root.getKeyMemory(key) );
    else
      root.load(function(){
        return callback( root.getKeyMemory(key) );
      });
  }

  static AwaitGetAllMemoryKey( key )
  {
    return new Promise((resolve)=>
    {
      this.getAllMemoryKey(key,resolve);
    })
  }


  //-------------------------------------------------------------
  // get all memory to array
  //-------------------------------------------------------------
  static getTabAllMemory( callback )
  {
    this.getAllMemory(function(data)
    {
      let tab = [];
      for( const buffer in data )
        tab.push( data[buffer] );
      callback( tab );
    });
  }

  static getTabAllMemoryKey( key, callback )
  {
    this.getAllMemoryKey(key, function(data)
    {
      let tab = [];
      for( const buffer in data )
        tab.push( data[buffer] );
      callback( tab );
    });
  }

  static AwaitGetTabAllMemoryKey( key )
  {
    return new Promise((resolve)=>
    {
      this.getTabAllMemoryKey(key,resolve);
    })
  }


  static getTabAllMemoryClone( callback )
  {
    this.getTabAllMemory((data)=>
    {
      callback( JSON.parse(JSON.stringify(data)) );
    });
  }



  //-------------------------------------------------------------
  // get memory
  //-------------------------------------------------------------
  static getMemory( uid, callback)
  {
    let root = this;

    if(!uid)
      return callback(null);

    //exec
    if(root.memory_load[root.entity])
      return callback( root.getKeyMemory(undefined, uid) );
    else
      return root.load(function()
      {
        callback( root.getKeyMemory(undefined, uid) )
      });
  }

  static AwaitGetMemory( key )
  {
    return new Promise((resolve)=>
    {
      this.getMemory(key,resolve);
    })
  }

  //-------------------------------------------------------------
  // get memory clone
  //-------------------------------------------------------------
  static getMemoryClone( uid, callback )
  {
    let root = this;
    root.getMemory( uid, (elem)=>
    {
      let clone = JSON.parse(JSON.stringify(elem));
      callback( clone );
    });
  }

  //-------------------------------------------------------------
  // Load member
  //-------------------------------------------------------------
  static load( callback )
  {
    let root = this;

    //Create callback memory request
    if(root.memory_callback[root.entity] == undefined )
      root.memory_callback[root.entity] = [];

    //Save callback memory
    root.memory_callback[root.entity].push( callback );

    //message
    Vue.prototype.$bddRefresh.setInfo(' Chargement '+root.entity+' ...');

    //load member
    if( root.memory_callback[root.entity].length == 1 )
    Vue.prototype.$srvApi.Req('get', root.url)
    .then(function( rep )
    {
      let data = rep.data;

      //message de traitement des data
      Vue.prototype.$bddRefresh.setInfo(' Traitement '+root.entity+' ...');

      //Traitement des datas
      data = root.TimeZoneAdjust( data );
      root.keyParse(data);

      //Message de fin de chargement
      Vue.prototype.$bddRefresh.setInfo(root.entity+' chargé');

      //load terminé flag
      root.memory_load[root.entity] = true;

      //all success
      for(var i=0; i<root.memory_callback[root.entity].length; i++ )
      if(root.memory_callback[root.entity][i] != undefined )
        root.memory_callback[root.entity][i]();

      //reset memory callback
      root.memory_callback[root.entity] = [];
    });
  }


  static keyParse(data, reset=true, config_name= null)
  {
    let root = this;

    let g_pkey   = this.pkey;
    let g_entity = this.entity
    if(config_name)
    {
      g_pkey   = this.memory_config[config_name].key
      g_entity = this.memory_config[config_name].entity
    }
    g_entity = g_entity.toLowerCase()

    if(reset)
      root.memory[g_entity] = {};
    
    for( var k=0; k<g_pkey.length; k++)
    {
      //get key
      let fkey = g_pkey[k];
      let key = fkey;

      //special ?
      if(fkey.name)
        key = fkey.name;

      //parse
      for( var i=0; i< data.length; i++)
      {
        let uid = data[i].uid;
        let pkey = key;

        //si fonc special
        if(fkey.fonc)
          pkey = fkey.fonc(data[i]);
        else
        if(key != 'uid')
          pkey = data[i][key];

        //enlever les null ou undefined
        if(pkey)
        {
          //si vide
          if( root.memory[g_entity] == undefined )
            root.memory[g_entity] = {};

          if(!root.memory[g_entity][pkey])
            Vue.set( root.memory[g_entity], pkey, {} )//root.memory[g_entity][pkey] = {};

          //add elem
          let data_buffer = root.memory[g_entity][pkey][uid]
          Vue.set( root.memory[g_entity][pkey], uid,  Object.assign({}, data_buffer, data[i]) )//root.memory[g_entity][pkey][uid] = data[i];
        }
      }
    }
  }


  static TimeZoneAdjust( data )
  {
    for( let i=0; i< data.length; i++)
    {
      if(data[i]['date'])
        data[i]['date'] = Fdate.TimeZoneAdjust( data[i]['date'] );
      
      if(data[i]['createAt'])
        data[i]['createAt'] = Fdate.TimeZoneAdjust( data[i]['createAt'] );
      
      if(data[i]['memberAt'])
        data[i]['createAt'] = Fdate.TimeZoneAdjust( data[i]['memberAt'] );
    }
    return data;
  }


  static keyDelete( uid, config_name= null )
  {
    let g_entity = this.entity
    if(config_name)
      g_entity = this.memory_config[config_name].entity

    let root = this;
    let tab_find = root.memory[g_entity]

    //uid_find
    for( var i in tab_find)
    if(i==uid)
      delete tab_find[i]
    
    //by key
    for( var p in tab_find)
    {
      var buffer = tab_find[p]
      for( var n in buffer )
      if(n==uid)
        delete buffer[n]
    }
  }


  //-------------------------------------------------------------
  // get mémoire
  //-------------------------------------------------------------
  static getKeyMemory( key, uid )
  {
    let root = this;

    if(!key)
      key = root.pkey[0];

    if(uid)
      return root.memory[root.entity][key][uid];
    return root.memory[root.entity][key];
  }


  //-------------------------------------------------------------
  // get mémoire clone
  //-------------------------------------------------------------
  static getKeyMemoryClone( key, uid )
  {
    let root = this;
    let elem = root.getKeyMemory( key, uid );
    return JSON.parse(JSON.stringify(elem));
  }
  
  //-------------------------------------------------------------
  // refresh
  //-------------------------------------------------------------
  static refresh()
  {
    root.memory_load[root.entity] = false;
    this.load();
  }


  //-------------------------------------------------------------
  // refresh by log
  //-------------------------------------------------------------
  static refreshByLog()
  {
    return new Promise(( resolve, reject )=>
    {
      if(Vue.prototype)
      if(Vue.prototype.$srvApi)
      if(Vue.prototype.$srvApi.isLocalLogin())
      {
        //Build date dernier refresh
        let dformat = Fdate.getISO_8601( this.memory_time )

        //Update memory time
        let new_memory_time = new Date()
        new_memory_time.setSeconds( new_memory_time.getSeconds()-2)//Adjust time offset

        //Calcule du ping
        //let ping = new_memory_time.getSeconds()- this.memory_time.getSeconds() ;
        //console.log( 'PING: '+ping )
        //if( ping > 60)
        //  Vue.prototype.$notify.error('Réseau lent','Votre connexion internet est lente !');

        try
        {
          //Send upd dernier refresh
          Vue.prototype.$srvApi.Req('get', 'log/'+encodeURI(dformat) )
          .then(( rep )=>
          {
            //compteur de fin de chargement
            let compte = 0;
            let nb     = 0

            //--------------------------
            //final fonction
            let endfinal = ()=>
            {
              compte++
              if( compte >= nb )
              {
                this.memory_time = new_memory_time
                resolve(compte)
              }
            }
    
            //--------------------------
            //let fonc gestion
            let foncGest = ( log )=>
            {
              let table_cible = log.ctable.toLowerCase()
              if( this.memory_load[ table_cible ])
              {
                //end compteur add
                nb++

                //Action selon le mode
                switch( log.mode )
                {
                  //==============================================
                  case 'add':
                  case 'upd':{
                      let url = this.memory_config[ table_cible ].url+'/'+log.cible
                      //console.log( url )
                      Vue.prototype.$srvApi.Req('get', url)
                      .then(( rep )=>
                      {
                        if(rep.status=="success")
                        {
                          rep.data = this.TimeZoneAdjust( rep.data );
                          this.keyParse(rep.data, false, table_cible )
                        }
                        endfinal()
                      })
                    break;
                  }
                  //==============================================
                  case 'del':{
                    this.keyDelete(log.cible, table_cible)
                    endfinal()
                    break;
                  }
                }
              }
            };
            
            //--------------------------
            //Parse data rep
            let data = rep.data
            for( var i=0; i<data.length; i++ )
              foncGest( data[i] )

            //si aucune modif log
            if(nb==0)
            {
              this.memory_time = new_memory_time
              return resolve(0)
            }

          })
          //Err réseau ?
          .catch((err)=>{
            Vue.prototype.$notify.error('Internet inaccesible','Veuillez vérifier votre connexion !')
            //alert('Internet inaccesible');
            reject(err)
          })
          return
        }
        catch(err){
          reject(err);
        }
      }
      return resolve(0)
    })
  }




  //-------------------------------------------------------------
  // Boucle sur data auto
  //-------------------------------------------------------------
  static datafor( input_table, callback_load )
  {
    return new Promise(function( resolve )
    {
      let data = [];
      let compte = 0;
      let nb     = Utils.ObjLength(input_table);

      //End fonc
      let end = function( rep )
      {
        //add
        if(rep)
        data.push( rep );

        //test de fin de boucle
        compte++;
        if(compte>=nb)
          resolve(data);
      };

      //main boucle
      for( const dd in input_table )
        callback_load(input_table[dd], end);

      //aucun résultat
      if(nb==0)
        return resolve(data);
    });
  }

  //-------------------------------------------------------------
  // Boucle sur data auto input
  //-------------------------------------------------------------
  static dataForAll( callback_load )
  {
    let root = this;
    return new Promise(function( resolve )
    {
      //get all data memory
      root.getAllMemory( function(list_data)
      {
        //boucle
        root.datafor( list_data, callback_load)
        .then(function(rep_data)
        {
          //end
          resolve(rep_data);
        });
      });
    });
  }

  //-------------------------------------------------------------
  static dataForKey( key, callback_load )
  {
    let root = this;
    return new Promise(function( resolve )
    {
      //get all data memory
      root.getAllMemoryKey( key, function(list_data)
      {
        //boucle
        root.datafor( list_data, callback_load)
        .then(function(rep_data)
        {
          //end
          resolve(rep_data);
        });
      });
    });
  }



  //-------------------------------------------------------------
  // Update
  //-------------------------------------------------------------
  static update( uid, input_tab )
  {
    let root = this
    let elem = root.memory[root.entity]['uid'][uid]

    //build modif only
    let compte = 0;
    let tab = {};
    for( var c in input_tab )
    if( input_tab[c] != elem[c])
    {
      compte++;
      tab[c] = input_tab[c]
    }
    //console.log('------');
    //console.log( tab );

    //Send modif
    return new Promise(( resolve, reject) => 
    {
      if(compte==0)
        return resolve()

      Vue.prototype.$srvApi.Req('patch', this.url+'/'+uid, tab )
      .then(function( rep )
      {
        if(rep.status == "success")
        {
          //update memory
          //tab.uid = uid
          //root.keyParse(tab, false, null )
          for( var p in tab )
            root.memory[root.entity]['uid'][uid][p] = tab[p]

          //ok
          return resolve()
        }
        else
        {
          alert('Modification impossible')
          return reject(rep)
        }
        
      })
    })
  }


  //-------------------------------------------------------------
  // Delete
  //-------------------------------------------------------------
  static remove( uid )
  {
    let root = this
    return new Promise(( resolve, reject) => {
      Vue.prototype.$srvApi.Req('delete', this.url+'/'+uid )
      .then(function( rep )
      {
        if(rep.status == "success")
        {
          //remove memory
          root.keyDelete(uid)

          //ok
          return resolve()
        }
        else{
          alert('Suppression impossible')
          return reject(rep)
        }
      })
    })
  }


  //-------------------------------------------------------------
  // Add
  //-------------------------------------------------------------
  static add( data )
  {
    return new Promise(( resolve, reject) => {
      Vue.prototype.$srvApi.Req('post', this.url, data )
      .then(( rep )=>
      {
        //verif success
        if(rep.status == "success")
        {
          let uid = rep.data
          //get add
          Vue.prototype.$srvApi.Req('get', this.url+'/'+uid )
          .then(( rep2 )=>
          {
            if(rep2.status == "success")
            {
              //add local memory
              rep2.data = this.TimeZoneAdjust( rep2.data );
              this.keyParse(rep2.data, false);

              //resolve ok
              return resolve(uid)
            }
            else
              return reject(rep)
          })
        }
        else
        {
          //alert('Ajout impossible: '+rep.message)

          switch(rep.message)
          {
              case 'doublons phone':
                  alert('Numéro de téléphone déja enregistré !');
              break;
              case 'seance type used':
                alert('Contractuel déjà effectué');
              break;
              case 'no credit':
                alert('Aucun crédit disponible pour réservation');
              break;
              case 'member not find or no phone':
                alert('Acun numéro de téléphone pour ce membre !')
              break;
              case 'seance type:e/c used':
                alert('Ce membre a déjà utilisé sa séance contractuelle. Veuillez lui faire une facture.');
                break;
              default:
                  alert('Insertion erreur: '+rep.message);
              break;
          }
          return reject(rep)
        }
      })
    })
  }
  
}




//-----------------------------------------------------------
//    FONCTION DE REFRESH DE LA BDD LOCAL
//-----------------------------------------------------------
function refreshLocalBdd()
{
  let time = 5000
  //console.log('REFRESH LOCAL BDD')
  Sdata.refreshByLog()
  .then((nb)=>{
    //Refresh window
    if(nb>0)
    {
      //refresh champs recherche
      if(window.callbackRefreshDataSearch)
        window.callbackRefreshDataSearch(false)//desative le verroue
      
      //refresh général
      autoRefresh.refresh()
    }
    //refresh fonc
    setTimeout(refreshLocalBdd, time)
  })
  .catch(()=>{
    setTimeout(refreshLocalBdd, time)
  });
}
//-----------------------------------------------------------
//Empecher la multi création de cette événement refresh
if(window.timeOutSdataMemory==undefined)
{
  window.timeOutSdataMemory = 1
  refreshLocalBdd()
}