

let callback = undefined

export default {

    SetCallback( _callback )
    {
        callback = _callback
    },

    refresh()
    {
        if(callback)
            callback()
    },

    clear()
    {
        callback = undefined
    }
}