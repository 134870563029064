import Sdata from '@/database/sdata'

let g_entity = 'caisse_item'
let g_url    = 'studio/item'
let g_pkey   = ['uid','caddy','member']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Item extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey
}