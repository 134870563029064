


import Member from '@/database/models/member'
import Commentary from '@/database/models/commentary'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Cloture from '@/database/models/cloture'
import Cgv from '@/database/models/cgv'
import Machine from '@/database/models/machine'
import Terminal from '@/database/models/terminal'
import Booking from '@/database/models/booking'
import Booking_hour from '@/database/models/booking_hour'
import Booking_close from '@/database/models/booking_close'
import Channel from '@/database/models/channel'
import Channel_link from '@/database/models/channel_link'
import Catalogue from '@/database/models/catalogue'
import ListCompte from '@/database/models/list_compte'
import Dpo from '@/database/models/dpo'
import Studio from '@/database/models/studio'
// import Mailjet from '@/database/models/mailjet'


export default {

    //Install Vue plugin
    install (Vue)
    {
        let full_refresh = false;

        let info_load = 'Initialisation';


        //=================================================================
        //Création de l'objet bddRefresh
        //=================================================================
        Vue.prototype.$bddRefresh = {


            //------------------------------------------------------------
            // Get etat installation
            //------------------------------------------------------------
            getInfo:function()
            {
                return info_load;
            },
            setInfo:function( msg )
            {
                info_load = msg;
            },

            //------------------------------------------------------------
            // All Actualise
            //------------------------------------------------------------
            actualise:function( callback )
            {
                //let root = this;
                if(full_refresh)
                    return;
                full_refresh = true;

                //Actualise le token
                Vue.prototype.$srvApi.refreshToken(function()
                {
                    info_load = 'Chargement ...';

                    //Liste fonc refresh steap 1
                    Member.load(function(){
                    Invoice.load(function(){
                    Machine.load(function(){
                    Terminal.load(function(){
                    Item.load(function(){
                    Cgv.load(function(){
                    Commentary.load(function(){
                    Booking.load(function(){
                    Booking_hour.load(function(){  
                    Booking_close.load(function(){
                    Channel.load(function(){
                    Channel_link.load(function(){
                    Catalogue.load(function(){
                    ListCompte.load(function(){
                    Dpo.load(function(){
                    Cloture.load(function(){
                    Studio.load(function() {
                    // Mailjet.load(function()
                    // {
                        //fin installation
                        info_load = 'installation ok';
                        full_refresh = false;
                        //callback
                        if(callback)
                            callback();
                    // });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                    });
                });
            },

            //------------------------------------------------------------
            /* END CLASS */
            //------------------------------------------------------------
            //Ok
        }
    }
}