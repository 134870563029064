import Sdata from '@/database/sdata'

let g_entity = 'channel_list'
let g_url    = 'studio/channelview'
let g_pkey   = ['uid','channel','member']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Channel_link extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey
}