import Sdata from '@/database/sdata'

let g_entity = 'member_commentary'
let g_url    = 'studio/commentary'
let g_pkey   = ['uid','member']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Commentary extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey
}