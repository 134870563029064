import Sdata from '@/database/sdata'

let g_entity = 'caisse_cgv'
let g_url    = 'studio/cgv'
let g_pkey   = ['uid','terminal']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Cgv extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey
}