import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import uuid from '@/assets/utils/uuid'
import autoRefresh from '@/assets/utils/autoRefresh'


let memory_refresh = [];

export default {

    //Install Vue plugin
    install (Vue, options)
    {
        //Prefix memoire
        const prefix = {
            token_name   : 'api_token',
            refresh_name : 'api_refresh',
            user_uid     : 'api_user',
            url          : 'https://api.studioconnect.fr/'/*'http://127.0.0.1:9010/'*/,
            crypto       : new SimpleCrypto( uuid.get() ),
            bddClean     : true,
        };

        //prefix data
        const data = {
            studio_uid   : options.studio,
            terminal_uid : options.terminal,
            save         : false,
        };

        /*Set value du storage*/
        function setValue( name, value, save )
        {
            //crypt value
            value = prefix.crypto.encrypt(value);
            //Set storage
            if(save)
                localStorage.setItem(name, value);
            else
                sessionStorage.setItem(name, value );
        }
        /* Récupérer une value du storage */
        function getValue(name)
        {
            //get storage
            let value = sessionStorage.getItem(name);
            if(!value)
                value = localStorage.getItem(name);
            
            //decrypt value
            if(value)
            {
                try{
                    return prefix.crypto.decrypt(value);
                }
                catch(err){
                    localStorage.clear();
                    sessionStorage.clear();
                }
            }
            return null;
        }

        /*Fabrique un header Authorization pour les requettes HTTP */
        function buildHeadersBearer( token_name )
        {
            return{
                    headers: { Authorization: `Bearer `+getValue(token_name) }
            };
        }

        //=================================================================
        //Création de l'objet srvApi
        //=================================================================
        Vue.prototype.$srvApi = {


            //-------------------------------------------------------------
            /* Renvoi UID du studio logé */
            //-------------------------------------------------------------
            getStudio:function(){
                return  data.studio_uid
            },

            //-------------------------------------------------------------
            /* Construction d'une URL */
            //-------------------------------------------------------------
            buildUrl:function(url)
            {
                return prefix.url + data.studio_uid +'/'+ url;
            },



            //-------------------------------------------------------------
            /* Login */
            //-------------------------------------------------------------
            login:function( login, password, save )
            {
                let root = this;

                //Set Mode save
                data.save = save;

                //Promise return
                return new Promise(function( resolve, reject)
                {
                    //Axios request
                    axios.post( root.buildUrl('auth/login'),{
                        studio   : data.studio_uid,
                        login    : login,
                        password : password,
                        terminal : data.terminal_uid
                    })
                    .then(function(response)
                    {
                        let rep = response.data;
                        if(rep.status == "success")
                        {
                            //Set token key
                            setValue( prefix.token_name,   rep.data.token,   save );
                            setValue( prefix.refresh_name, rep.data.refresh, save );

                            //Get USER UID
                            root.Req( 'get', '/user' )
                            .then(function(rep)
                            {
                                //Save UID member
                                setValue( prefix.user_uid, rep.data[0].uid, save );
                                //resolve final
                                return resolve();
                            });
                            return;
                        }
                        return reject();
                    });
                });
            },




            //-------------------------------------------------------------
            /* deconnexion*/
            //-------------------------------------------------------------
            logout:function( redirect=true, page="/")
            {
                //console.log('!! LOGOUT DEMANDE !!');
                //console.log("Deconnexion ...");

                //Clear autoRefresh
                autoRefresh.clear()
                //Remove all storage
                localStorage.clear();
                sessionStorage.clear();
                window.indexedDB.databases()
                .then((r) => {
                    
                    if(prefix.bddClean)
                    for (var i = 0; i < r.length; i++) 
                        window.indexedDB.deleteDatabase(r[i].name);

                }).then(() => {
                    //Final delete
                    if(redirect)
                        document.location.href= page; 
                        //Vue.prototype.$router.push( page ).catch(()=>{});
                });
            },

            //-------------------------------------------------------------
            /* Refresh Token */
            //-------------------------------------------------------------
            refreshToken:function( callback )
            {
                let root = this;
                //save request pour non paralléle
                memory_refresh.push(callback);

                if( memory_refresh.length == 1 )
                axios.get( root.buildUrl('auth/refresh'),
                buildHeadersBearer( prefix.refresh_name ))
                .then(function(response)
                {
                    if(response.data.status == "success")
                    {
                        //Set new token key
                        setValue( prefix.token_name,   response.data.data.token,   data.save );
                        setValue( prefix.refresh_name, response.data.data.refresh, data.save );

                        //all success
                        for(var i=0; i<memory_refresh.length; i++ )
                        if(memory_refresh[i] != undefined )
                            memory_refresh[i]();
                        memory_refresh =[];
                        return;
                    }
                    else
                        root.logout(true, '/error/token');
                });
            },




            //-------------------------------------------------------------
            /* vérifie si on est logé en local */
            //-------------------------------------------------------------
            isLocalLogin:function()
            {
                if( getValue(prefix.token_name) )
                if( getValue(prefix.refresh_name) )
                if( getValue(prefix.user_uid) )
                    return true;
                return false;
            },



            //-------------------------------------------------------------
            /* get uid user login */
            //-------------------------------------------------------------
            getLoginUserUid:function()
            {
                let root = this;

                if(root.isLocalLogin() )
                    return getValue( prefix.user_uid );
                return root.logout();
            },



            //-------------------------------------------------------------
            /* reset password Login etape 1 */
            //-------------------------------------------------------------
            resetUserSendCode:function( input_phone, input_login, input_password )
            {
                let root = this;
                return new Promise(function(resolve, reject )
                {
                    //Axios request
                    axios.post( root.buildUrl('auth/reseturl'),{
                        studio   : data.studio_uid,
                        phone    : input_phone,
                        login    : input_login,
                        password : input_password
                    })
                    .then(function(response)
                    {
                        if(response.data.status == 'success')
                            return resolve();
                        return reject();
                    });
                });
            },



            //-------------------------------------------------------------
            /* reset password Login etape 2 */
            //-------------------------------------------------------------
            resetUserUpd:function( input_code )
            {
                let root = this;
                return new Promise(function( resolve, reject )
                {
                    //Axios request
                    axios.put( root.buildUrl('auth/reseturl'),{
                        studio   : data.studio_uid,
                        code     : input_code
                    })
                    .then(function(response)
                    {
                        if(response.data.status == 'success')
                            return resolve();
                        return reject();
                    });
                });
            },


            //-------------------------------------------------------------
            /* Auto refresh token response */
            //-------------------------------------------------------------
            Req:function( method, url_path, data=null )
            {
                let root = this;
                return new Promise(function(resolve, reject )
                {
                    root.AxiosAutoRefreshFonc( resolve, reject, method, url_path, data);
                });
            },
            //Fonction 2 pour répétition
            AxiosAutoRefreshFonc:function( resolve, reject, method, url_path, data)
            {
                let root = this;
                let prom = null

                //date bool cleaner
                for(var d in data )
                if( typeof(data[d]) == 'boolean')
                {
                    if(data[d]==true)
                        data[d] = 1
                    if(data[d]==false)
                        data[d] = 0
                }

                //Axios request
                if(method=="get" || method=="delete")
                    prom = axios[method]( root.buildUrl(url_path),
                    buildHeadersBearer( prefix.token_name ),
                    data )
                else
                    prom = axios[method]( root.buildUrl(url_path),
                    data,
                    buildHeadersBearer( prefix.token_name ) )

                //Send server
                prom.then(function(response)
                {
                    //Si unauthorized alors on tante un refresh du token
                    if( response.data.message == "token error" )
                    return root.refreshToken(function()
                    {
                        return root.AxiosAutoRefreshFonc( resolve, reject, method, url_path, data );
                    });
                    //Ok on renvoi la réponse
                    resolve( response.data );
                })
                .catch(function(err){
                    //renvoi
                    setTimeout(()=>{
                        return root.AxiosAutoRefreshFonc( resolve, reject, method, url_path, data);
                    },5000)
                    //erreur
                    return reject(err);
                });
            },

            
            //-------------------------------------------------------------
            /* Upload file */
            //-------------------------------------------------------------
            ReqUpload:function( url_path, file )
            {
                let root = this;
                
                return new Promise((resolve, reject)=>
                {
                    let axiosOptions = buildHeadersBearer( prefix.token_name )
                    axiosOptions.headers['Content-Type'] = 'multipart/form-data';
    
                    //refresh token
                    return root.refreshToken(function()
                    {
                        let formData = new FormData();
                        formData.append('file', file);
    
                        axios.post( 
                            root.buildUrl(url_path), 
                            formData, 
                            axiosOptions )
                        .then(function(response){
                            resolve( response.data )
                        })
                        .catch(function(err){
                            reject(err);
                        });
                    });
                })
            }


            //-------------------------------------------------------------
            /* END CLASS */
            //-------------------------------------------------------------
            //Ok
        };
    }
  }