import Sdata from '@/database/sdata'

let g_entity = 'channel'
let g_url    = 'studio/channel'
let g_pkey   = ['uid']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Channel extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey
}