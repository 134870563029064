
/*Fonction de traitement des dates*/
var tab_day   = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche'];
var tab_month = ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Decembre'];

export default {
    
    tab_day:tab_day,
    tab_month:tab_month,

    getDate:function ( actu, min )
    {
        if(!actu)
            return null;

        var today = new Date( actu );

        var dd   = today.getDate();
        var jour = tab_day[today.getDay()];
        var mm   = tab_month[today.getMonth()]; //January is 0!
        var yyyy = today.getFullYear();

        if( min )
            return jour+' '+dd;
        return jour+' '+dd+' '+mm+' '+yyyy;
    },


    getHeure:function( actu, all=false )
    {
        if(!actu)
            return null;
            
        var today = new Date( actu );
        var h = today.getHours();
        var m = today.getMinutes();
        var s = today.getSeconds();

        if( h < 10 )
            h = '0'+h;
        if( m < 10 )
            m = '0'+m;
        if( s < 10 )
            s = '0'+s;

        if(!all)
            return h+':'+m;
        else
        return h+':'+m+':'+s;
    },


    getFullDate:function( actu )
    {
        return this.getDate( actu )+' à '+this.getHeure( actu )+'h';
    },
    

    getFullDateMin:function( actu, us=false, sep='/' )
    {
        if(actu == undefined )
            return '';
        if(actu == "0000-00-00")
            return '';

        var today = new Date( actu );

        if(!today)
        return '';

        var jour = today.getDate();
        var mm   = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(jour < 10 )
            jour = '0'+jour;
        if(mm < 10 )
            mm = '0'+mm;

        if(us)
            return yyyy+sep+mm+sep+jour;
        return jour+sep+mm+sep+yyyy;
    },


    getInputDate:function( actu )
    {
        if( actu == '0000-00-00' )
            return '';

        let d = new Date( actu );

        let day   = d.getDate();
        let month = (d.getMonth()+1);

        if( day < 10 )
            day = "0"+day;
        if( month < 10 )
            month = "0"+month;

        return d.getFullYear()+'-'+month+'-'+day;
    },


    getMonth:function( actu )
    {
        if(!actu)
            return null;
            
        var today = new Date( actu );
        return tab_month[today.getMonth()]
    },


    getISO_8601 :function( actu )
    {
        if(!actu)
            return null;
        return this.getInputDate(actu)+' '+this.getHeure(actu,1)
    },


    getTabMonth()
    {
        return tab_month;
    },



    getMonday( date )
    {
        date = new Date( date )
        var day = date.getDay();
        var prevMonday;

        if(date.getDay() == 0){
            prevMonday = date.setDate(date.getDate() - 7);
        }
        else{
            prevMonday = date.setDate(date.getDate() - day);
        }

        return prevMonday;
    },



    TimeZoneAdjust( input_date )
    {
        var new_input_date = new Date( Date.parse(input_date) );

        let timezone = (new Date()).getTimezoneOffset()/60;
        new_input_date.setHours(new_input_date.getHours()+(timezone>>0)+1);//+2 ?
        return new_input_date;
    }  
}