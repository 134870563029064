import Sdata from '@/database/sdata'

let g_entity = 'caisse_catalogue'
let g_url    = 'studio/catalogue'
let g_pkey   = ['uid','categorie']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Catalogue extends Sdata
{
  static entity = g_entity
  static url    = g_url
}