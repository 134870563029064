import Vue from 'vue'
import Member  from '@/database/models/member'

import Sdata from '@/database/sdata'

let g_entity = 'caisse_caddy'
let g_url    = 'studio/caddy'
let g_pkey   = ['uid','terminal','member']
Sdata.addConfig( g_entity, g_pkey, g_url )

export default class Invoice extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey

  //-------------------------------------------------------------
  /* to get Utilisateur Role */
  //-------------------------------------------------------------
  static getTypeName( input_data )
  {
    if(input_data == null )
      return '?';

    let rep = '?';
    switch(input_data.type)
    {
      case 'fa':
        rep = 'Facture';
      break;
      case 're':
        rep = 'Reçu';
      break;
      case 'av':
        rep = 'Avoir';
      break;
    }
    return rep;
  }


  //-------------------------------------------------------------
  /* facture is actif ? */
  //-------------------------------------------------------------
  static getActif( expire, date )
  {
    if(date == null )
      return true;
    if(expire == 0 )
      return true;

      //aujourd'hui
      let actu = (new Date()).getTime();
      //fin abo
      let d = (new Date( this.getDateFin(expire, date))).getTime();

      //comparé date
      if( actu > d )
        return false;
      return true;
  }

  //-------------------------------------------------------------
  //calcule fin credit
  //-------------------------------------------------------------
  static getDateFin( expire, date )
  {
    if(date == null )
      return new Date();
    if(expire == 0 )
      return new Date();

    //fin abo
    let d = new Date( date );
    d = d.addDays( expire );
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
      
    return d;
  }

  static getDateDebut( date )
  {
    if(date == null )
      return new Date();

    //fin abo
    let d = new Date( date );
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
      
    return d;
  }


  //-------------------------------------------------------------
  //Crée une facture
  //-------------------------------------------------------------
  /*
      let data = {
        type     : 'fa',
        member   : 'UIDV4',
        payement : 'JSON PAYEMENT',
        item     : 'JSON ITEM,
    }
  */
  static add( data )
  {
    let finalRefresh = ( resolve, uid_caddy )=>
    {
      //léger attente de traitement
      setTimeout(()=>{
        //Auto Refresh de la BDD
        Sdata.refreshByLog()
        .then(()=>{
          resolve( uid_caddy )
        })
      },1000)
    }

    return new Promise(( resolve, reject) =>{
      //Envoi de la facture
      Vue.prototype.$srvApi.Req('post', g_url, data)
      .then(( rep )=>
      {
          //err ?
          if(rep.status != 'success')
              return reject(rep)

          //mise a jour fiche membre
          Member.getMemory( data.member, (mbr)=>
          {
            //si membre essai alors activation
            if( mbr.type=='e')
            {
              Member.update(data.member,{
                type:'m',
                active:1
              })
              .then(()=>{
                finalRefresh(resolve, rep.data)
              })
            }
            else
              finalRefresh(resolve, rep.data)

          })
      })
    })
  }


/*
  static getCreditDetails( facture, callback )
  {
    let root = this;
    //get item
    Item.getItemParse( facture.member, function(item_list)
    {
      //Get booking Member
      Booking.getTabAllMemoryKey(facture.member, function(booking)
      {
        //Item propre a cette facture
        let list_item = {};
        for( var m=0; m<item_list.length; m++)
        if( item_list[m].caddy == facture.uid )
          list_item[ item_list[m].uid ] = item_list[m];

        //Bouclage et remplissage memoire
        Item.datafor(list_item, function(data, end)
        {
          if(data.credit == 0 )
            return end();
          else
          Machine.getMemory(data.machine,function(machine)
          {
            let credit_use = 0;
            let name = 'global';
            let resa = booking;
            if(machine)
            {
              //nom machine
              name = machine.name;
              //filtrer res
              resa = {};
              for( var i=0; i<booking.length; i++)
              if( booking[i].machine == data.machine )
                resa.push(booking[i]);
            }

            //calcule crénaux date OK
            if(!data.favoir)//si pas d'avoir dessus
            if(resa.length>0)
            {
              let d_start = (new Date( data.date)).getTime(); 
              let d_end   = (new Date( root.getDateFin(data.expire, data.date) )).getTime();
              for( var c=0; c<resa.length; c++)
              {
                let dd = (new Date( resa[c].date)).getTime();
                if( dd >=d_start)
                if( dd <=d_end)
                  credit_use++;
              }
              //si aucune limitation
              if( data.expire == 0)
              if( credit_use> data.credit )
                credit_use = data.credit;
            }

            //calcule crédit
            let cbuffer = {
              uid     : data.uid,
              credit  : data.credit,//-1 = illimité
              used    : credit_use,
              name    : name,
              item    : data.name,
              type    : data.type,
              expire  : root.getDateFin(data.expire, data.date),
              status  : root.getActif(data.expire, data.date),
              favoir  : data.favoir,
              fname   : data.fname,
            };
            //END
            end(cbuffer);
          });
        })
        //Final tout est chargé
        .then(function(rep)
        {
          callback(rep);
        });
      });
    });
  }
*/

}


Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}